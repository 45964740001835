.section-game-review {
    width: 93rem;
    display: flex;
    margin: 0 auto;
    gap: 9.5rem;

    @media (max-width: $phone-width) {
        width: 100%;
        padding: 4rem 3rem 2rem 3rem;
        flex-direction: column;
        gap: 4.5rem;
    }

    &__review {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;

        .breadcrumbs {
            @media (max-width: $phone-width) {
                display: none;
            }
        }

        .review-title-holder {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            .img-holder-mobile {
                display: none;
                // @media (max-width: $phone-width) {
                //     display: block;
                //     margin: 0 auto;
                //     img {
                //         width: 100%;
                //         height: 100%;
                //     }
                // }
            }
        }
        .review-holder {
            width: 74rem;
            display: flex;
            flex-direction: column;
            gap: 3.2rem;

            @media (max-width: $phone-width) {
                // padding-left: 3rem;
                // padding-right: 3rem;
                width: auto;
                order: 2;
            }
            a {
                color: inherit;
                font-size: inherit;
                line-height: inherit;
                font-weight: bold;
                cursor: pointer;
                color: $color-primary;
                &:hover {
                    text-decoration: underline;
                }
            }
            blockquote p {
                
                font-size: 4rem;
                line-height: 5.2rem;
                font-weight: 800;
                color: $color-primary-blue;
                @media (max-width: $phone-width) {
                    font-size: 2.4rem;
                    line-height: 3.2rem;
                }
    
            }
            
            li, p {
                font-size: 2rem;
                line-height: 3.6rem;
                color: $color-primary-text;
                font-weight: 500;
                text-align: left;
                
    
                @media (max-width: $phone-width) {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }
            }
    
            h2 {
                text-transform: initial;
                font-size: 2.4rem;
                line-height: 3.2rem;
                @media (max-width: $phone-width) {
                    font-size: 2rem;
                    line-height: 2.8rem;
                }
            }
            h2 {
                @media (max-width: $phone-width) {
                    font-size: 2.8rem;
                    line-height: 3.6rem;
                }
            }

            h3 {
                font-weight: 800;
                font-size: 3.2rem;
                line-height: 4rem;
                color: $color-primary-text;

                @media (max-width: $phone-width) {
                    font-size: 2rem;
                    line-height: 2.8rem;
                }        
            }
            


            ul {
                margin-left: 4.8rem;
                margin-top: 2rem;
    
                li::marker {
                    color: $color-primary;
                    font-size: 2rem;
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        
        //width: 320px;

        @media (max-width: $phone-width) {
            width: 315px;
        }

        .img-holder {
            width: 300px;
            height: 300px;
            background: #f1f1f1;
            img {
                vertical-align: middle;
                @media (max-width: $phone-width) {
                    width: 315px;
                }        
            }
        }
        .info-holder {
            padding: 2.4rem;
            background-color: $color-white-darker;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            .info-item {
                font-size: 1.4rem;
                line-height: 2rem;
                color: $color-primary-text;
                font-weight: 500;

                span {
                    font-weight: bold;
                }
            }
        }
    }
}