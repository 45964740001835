.btn {
    &,
    &:link,
    &:visited {
        text-decoration: none;
        padding: 1.8rem 4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: background-color .1s;
        position: relative;
        font-size: $default-font-size;
        font-weight: 700;
        line-height: 2.4rem;
        //Change for the <button> element
        border: none;
        cursor: pointer;
        font-family: inherit;
        border-radius: 40px;

        @media (max-width: $phone-width) {
            padding: 1.6rem 3rem;
            font-size: 1.6rem;
            line-height: 1.9rem;
        }
    }
    
    &--green {
        background-color: $color-green-highlight;
        color: $color-white;

        &:hover {
            background-color: $color-green-dark;
            img {
                transform: translateX(4px);
            }
        }
    }
    &--purple {
        background-color: $color-primary;
        color: $color-white;
        &:hover {
            background-color: $color-purple-light;
            img {
                transform: translateX(4px);
            }
        }
    }
    &--blue {
        background-color:  $color-primary-blue;
        color: $color-white; 
        &:hover {
            background-color: $color-blue-light;
            img {
                transform: translateX(4px);
            }
        }  
    }
    
    &--cookie-policy {
        color: $color-white;
        background: $color-primary-text;
        box-shadow: inset 0px 0px 0px 2.8px $color-white;
        padding: 1.3rem 2.7rem;

        @media (max-width: $phone-width) {
            width: fit-content;
            text-align: left;
        }
    }

    img {
        margin-left: 1.6rem;
        transition: all .1s;

        @media (max-width: $phone-width) {
            width: 1.9rem;
            height: 1.6rem;
        }
    }
}
.breadcrumbs {
    .desktop-table-16 {
        text-transform: uppercase;
    }
    a:link, a:visited, a:hover, a:active {
        color: inherit;
    }
}
