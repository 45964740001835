.section-about {
    text-align: center;
    background-color: $color-white;

    @media (max-width: $phone-width) {
        padding: 0 3rem;
    }

    .row {
        @media (max-width: $tablet-width) {
            flex-direction: column;
        }
        @media (max-width: $phone-width) {
            align-items: flex-start;
        }
    }

    &__img-holder {
        width: 784px;

        @media (max-width: $phone-width) {
            width: 100%;
            height: auto;
        }
        img {
            height: 100%;
            width: 100%;
        }

        &--desktop {
            @media (max-width: $phone-width) {
                display: none;
            }
        }
        &--mobile {
            display: none;
            @media (max-width: $phone-width) {
                display: block;
            }
        }
    }

    &__steps {

        @media (max-width: $tablet-width) {
            padding: 0 6rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @media (max-width: $phone-width) {
            padding: 0;
        }

        &--step {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            @media (max-width: $phone-width) {
                justify-content: flex-start;
            }

            .step-number {
                font-weight: 800;
                font-size: 2.4rem;
                color: $color-white;
                background: $color-primary;
                border-radius: 50%;
                width: 4rem;
                height: 4rem;
                text-align: center;
                @media (max-width: $phone-width) {
                    width: 3.2rem;
                    height: 2.8rem;
                    font-size: 16px;
                }
            }
            .step-description {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
                width: 39rem;
                @media (max-width: 1040px) {
                    width: 33rem;
                }
                @media (max-width: $phone-width) {
                    width: 100%;
                }
            }
        }
    }
    .header__button-holder {
        padding: 0;
    }
}

.section-statistics {
    background-color: $color-primary-darker;
    position: relative;
    @media (max-width: $tablet-width) {
        padding: 0 6rem;
    }
    @media (max-width: $phone-width) {
        padding: 0 3rem;
    }
    .row {
        width: 112rem;
        margin: 0 auto;
        justify-content: space-between;
        align-items: flex-start;
        @media (max-width: $tablet-width) {
            width: 100%;
            gap: 4rem;
        }
        @media (max-width: $phone-width) {
            width: auto;
            gap: 4rem;
            flex-direction: column;
        }
    }

    &__text-holder {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
        width: 45.5rem;
        @media (max-width: $tablet-width) {
            width: 100%;
        }
        @media (max-width: $phone-width) {
            width: 100%;
            gap: 3.2rem;
        }
        &--text {
            display: flex;
            flex-direction: column;
            gap: 3.2rem;
        }

        .btn-holder-desktop {
            @media (max-width: $phone-width) {
                display: none;
            }
        }
    }
    &__eclipse {
        position: absolute;
        top: -6rem;
        left: -15.6rem;
        height: 24.8rem;
        width: 24.8rem;
        border-radius: 50%;
        background: rgba($color-green-highlight, 0.1);

        @media (max-width: $phone-width) {
            width: 12.8rem;
            height: 12.8rem;
            top: -3rem;
            left: 19.5rem;
        }

        &--small {
            width: 1.2rem;
            height: 1.2rem;
            background: rgba($color-green-highlight, 0.3);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: $phone-width) {
                width: 8px;
                height: 8px;
            }
        }
    }
    &__img-holder {
        width: 16.4rem;
        height: 16.4rem;
        position: absolute;
        top: 8.8rem;
        left: -22rem;

        @media (max-width: $phone-width) {
            width: 8rem;
            height: 8rem;
            left: 21.5rem;
            top: -7.2rem;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
    

    a {
        width: 26.9rem;
        @media (max-width: $phone-width) {
            width: auto;
        }
    }
    &__numbers-holder {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5.6rem 2rem;
        width: 55rem;
        margin-top: 14.6rem;
        
        @media (max-width: $phone-width) {
            width: 100%;
            gap: 2.4rem 1rem;
            margin-top: 0;
        }

        &--item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 1.2rem;
            @media (max-width: $phone-width) {
                gap: 8px;
            }

            .statistics-number {
                font-size: 12.4rem;
                line-height: 12.4rem;
                font-weight: 700;
                color: $color-white;
                @media (max-width: $phone-width) {
                    font-size: 7.2rem;
                    line-height: 7.2rem;
                }

                span {
                    font-size: 6rem;
                    line-height: 6rem;
                    @media (max-width: $phone-width) {
                        font-size: 3rem;
                        line-height: 3rem;
                    }
                }
            }
            .statistics-label {
                font-size: 1.8rem;
                line-height: 2.2rem;
                font-weight: bold;
                color: $color-green-highlight;
                @media (max-width: $phone-width) {
                    font-size: 1.2rem;
                    line-height: 2rem;
                }
            }
        }
    }
    .btn-holder-mobile {
        display: none;
        @media (max-width: $phone-width) {
            display: block;
        }
    }
}

.section-features {
    background: $color-white;
    position: relative;

    @media (max-width: $tablet-width) {
        padding: 0 6rem;
    }
    @media (max-width: $phone-width) {
        padding: 0 3rem;
    }

    .row {
        width: 112rem;
        margin: 0 auto;
        justify-content: space-between;

        &:last-child {
            justify-content: flex-start;
        }
        @media (max-width: $tablet-width) {
            width: 100%;
            gap: 4rem;
        }
        @media (max-width: $phone-width) {
            flex-direction: column;
            margin-bottom: 6rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__img-holder-desktop {
        width: 60rem;
        height: auto;
        @media (max-width: $tablet-width) {
            width: 100%;
        }
        @media (max-width: $phone-width) {
            width: 31.5rem;
            height: 24.4rem;
            display: none;
        }
        img {
            width: 100%;
            height: 100%;
        }
        
        &--slot-rating {
            width: 43.8rem;
            height: 43.8rem;
            @media (max-width: $tablet-width) {
                width: 100%;
            }

            @media (max-width: $phone-width) {
                width: 24.6rem;
                height: 25.2rem;
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__img-holder-mobile {
        display: none;
        @media (max-width: $phone-width) {
            width: 31.5rem;
            height: 24.4rem;
            display: block;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &--slot-rating {
            display: none;
            @media (max-width: $phone-width) {
                width: 24.6rem;
                height: 25.2rem;
                display: block;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
.section-testimonials {
    background-color: $color-primary-blue;
    position: relative;
    z-index: 15;
    overflow: hidden;
    @media (max-width: $tablet-width) {
        padding: 0 6rem;
    }
    @media (max-width: $phone-width) {
        padding: 0 3rem;
        overflow: visible;
    }

    .row {
        width: 112rem;
        margin: 0 auto;
        align-items: flex-start;
        padding-top: 10rem;
        gap: 12rem;
        @media (max-width: $tablet-width) {
            width: auto;
            gap: 6rem;
            padding-top: 4rem;
            flex-direction: column;
        }
        
    }

    &__speach-icon-holder {
        position: absolute;
        width: 17.4rem;
        height: 16.4rem;
        top: -2rem;
        right: -14.8rem;

        @media (max-width: $tablet-width) {
            width: 14.4rem;
            height: 13.6rem;
            top: -7.2rem;
            right: 0;
        }

        @media (max-width: $phone-width) {
            width: 10rem;
            height: 9.6rem;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
   
    &__quote-holder {
        display: flex;
        flex-direction: column;
        width: 55rem;
        padding-top: 4.4rem;
        padding-bottom: 2rem;

        @media (max-width: $tablet-width) {
            order: 3;
            width: auto;
            padding-top: 0;
        }

        .fade {
            // -webkit-animation-name: fade;
            // -webkit-animation-duration: .8s;
            // animation-name: fade;
            // animation-duration: .8s;
            height: 40rem;
            
            @media (max-width: $tablet-width) {
                height: 31rem;
            }            

            @media (max-width: $phone-width) {
                height: 24rem;
            }            
          }


          
          @-webkit-keyframes fade {
            from {transform: translate3d(28%, 0 ,0); opacity: 0.4;}
            to {transform: translate3d(0, 0 ,0); opacity: 1;}
          }
          
          @keyframes fade {
            from {transform: translate3d(28%, 0 ,0); opacity: 0.4;}
            to {transform: translate3d(0, 0 ,0); opacity: 1;}
        }

        blockquote {
            font-size: 4rem;
            line-height: 5rem;
            font-weight: 500;
            color: $color-white-darker;
            margin-bottom: 3.2rem;
            @media (max-width: $phone-width) {
                margin-bottom: 2.4rem;
                font-size: 2.4rem;
                line-height: 3.2rem;
            }
        }
        .testimonial-author {
            font-size: 1.8rem;
            line-height: 2.2rem;
            font-weight: 700;
            color: $color-white-darker;
            margin-bottom: 5.6rem;

            @media (max-width: $phone-width) {
                margin-bottom: 3.2rem;
                font-size: 1.2rem;
                line-height: 2rem;
            }
        }
        .bullets-arrows-holder {
            display: flex;
            justify-content: space-between;

            .bullets-holder {
                display: flex;
                gap: 2rem;
                align-items: center;

                .bullet {
                    width: 1rem;
                    height: 1rem;
                    background: $color-primary-light;
                    border-radius: 50%;
                    @media (max-width: $phone-width) {
                        width: 6px;
                        height: 6px;
                    }
                }
                
                .bullet.active {
                    width: 2rem;
                    height: 2rem;
                    background: $color-white-darker;
                    border-radius: 50%;
                    @media (max-width: $phone-width) {
                        width: 1.2rem;
                        height: 1.2rem;
                    }
                }
            }
            
        }
        .arrow-holder {
            svg {
                width: 5rem;
                height: 4.2rem;
                cursor: pointer;@media (max-width: $phone-width) {
                    width: 3.8rem;
                    height: 3.2rem;
                }
            }
            .arrow-left {
                transform: rotateY(180deg);
                margin-right: 4rem;
                @media (max-width: $phone-width) {
                    margin-right: 1.6rem;
                }
            }
        }
    }
    &__customers {
        width: 45rem;
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
        position: relative;

        @media (max-width: $tablet-width) {
            order: 1;
            width: auto;
            gap: 4.8rem;
        }
        @media (max-width: $phone-width) {
            gap: 3.2rem;
        }
    }
    &__logos {
        display: flex;
        align-items: center;
        width: 112rem;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 6rem;
        &--logo {
            img {
                vertical-align: middle;
            }
            @media (max-width: 1050px) {
                transform: scale(0.8);
            }
            @media (max-width: $small-devices-width) {
                transform: scale(0.7);
            }
         }
        @media (max-width: $tablet-width) {
            width:  100%;
        }
        @media (max-width: $phone-width) {
            margin-top: 6rem;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-evenly;
        }
        @media (max-width: $small-devices-width) {
            width: 100vw;
            margin-left: -3rem;
        }
    }
    &__trusted-text {
        text-align: center;
        font-size: 1.8rem;
        line-height: 3.2rem;
        color: $color-white-darker;
        font-weight: 700;
        margin-top: 4rem;

        @media (max-width: $phone-width) {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
}

.section-contact-form {
    background: $color-primary-extra-dark;
    overflow: hidden;
    position: relative;
    padding-top: 10rem;

    @media (max-width: $small-devices-width) {
        padding: 4rem 3rem 0 3rem;
    }

    .header__circle--big {
        background: $color-primary;
        top: -16.4rem;
        right: -19.1rem;
        z-index: 1;
        @media (max-width: $phone-width) {
            width: 8rem;
            height: 8rem;
            top: -2.4rem;
            right: 4.4rem;
        }
    }
    .header__circle--medium {
        background: $color-primary;
        top: 7.4rem;
        right: -34.6rem;
        z-index: 1;
        @media (max-width: $phone-width) {
            width: 5.2rem;
            height: 5.2rem;
            top: 5.6rem;
            right: -8px;
        }
    }
    .header__circle--small {
        background: $color-primary;
        top: 22.8rem;
        right: -17.3rem;
        z-index: 1;
        @media (max-width: $phone-width) {
            width: 4rem;
            height: 4rem;
            top: 10.8rem;
            right: 5.2rem;
        }
    }
    &__text-form-holder {
        max-width: 55rem;
        display: flex;
        flex-direction: column;
        gap: 5.6rem;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        z-index: 10;
        position: relative;

        @media (max-width: $small-devices-width) {
            gap: 4rem;
            justify-content: flex-start;
            align-items: flex-start;
            max-width: none;
        }

        &--text {
            display: flex;
            flex-direction: column;
            gap: 4.8rem;
            justify-content: center;
            text-align: center;
            z-index: 10;

            @media (max-width: $small-devices-width) {
                gap: 3.2rem;
                text-align: left;
            }

            .backgrounds-and-text {
                text-align: center;

                @media (max-width: $small-devices-width) {
                    text-align: left;
                }
            }
        }

        &--form {
            width: 100%;

            .form {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 2.4rem;
                width: 100%;

                @media (max-width: $small-devices-width) {
                    gap: 2rem;
                }

                .form-group {
                    width: 100%;

                    .form__input {
                        background: $color-white-darker;
                        width: 100%;
                        padding: 2.4rem;
                        border:  none;
                        font-family: 'Gilroy';

                        @media (max-width: $small-devices-width) {
                            padding: 2.2rem 1.6rem;
                        }
                    }
                }
            }
        }

    }
}
.section-founders {
    background: $color-primary;
    position: relative;
    
    .row {
        padding-top: 12rem;
        align-items: flex-start;
        padding-bottom: 2rem;

        @media (max-width: $tablet-width) {
            padding-left: 6rem;
            padding-right: 6rem;
            gap: 4rem;
        }

        @media (max-width: $phone-width) {
            padding: 4rem 3rem 3rem 3rem;
            flex-direction: column;
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
        max-width: 45.5rem;

        @media (max-width: $phone-width) {
            width: 100%;
            gap: 4rem;
        }

        &--logo-holder {
            width: 28.5rem;

            @media (max-width: $phone-width) {
                width: 21.4rem;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__right {
        display: flex;
        flex-direction: column;
        gap: 6.4rem;
        max-width: 55rem;

        @media (max-width: $phone-width) {
            gap: 4rem;
            max-width: none;
        }

        &--text {
            display: flex;
            flex-direction: column;
            gap: 3.2rem;

            @media (max-width: $phone-width) {
                gap: 2.4rem;
            }
        }
        &--founders {
            display: flex;
            flex-direction: row;
            gap: 2.4rem;
            align-items: center;

            @media (max-width: $phone-width) {
                flex-direction: column;
                align-items: flex-start;
                gap: 2rem;
            }

            .avatars-holder {
                display: flex;
                flex-direction: row;
                position: relative;
                width: 12.7rem;
                .avatar {
                    width: 7.5rem;
                    height: 7.5rem;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 7.5rem;
                        height: 7.5rem;
                    }
                    &1 {
                        background-color: $color-primary-light;
                    }
                    &2 {
                        background-color: $color-primary-lighter;
                        left: 5.2rem;
                        position: absolute;
                    }
                }
            }
            .avatar-text-holder {
                display: flex;
                flex-direction: column;
                

                p {
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    color: $color-white-darker;
                    font-weight: 700;
                    @media (max-width: $phone-width) {
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }
                }
                span {
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    font-weight: 500;
                    color: $color-white-darker;
                    @media (max-width: $phone-width) {
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }
                }
            }
        }
    }
}