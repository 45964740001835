*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
    
}

body {
    box-sizing: border-box;
    scroll-behavior: smooth;
    
}


a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

textarea {
    resize: none;
}
img {
    user-select: none;
}

main .email-link-style {
    color: #fff;
    font-size: inherit;
    line-height: inherit;
    font-weight: bold;
    cursor: pointer;

    &--purple {
        color: $color-primary;
    }

    &:hover {
        text-decoration: underline;
    }
}

main .email-link {
    cursor: pointer;
}
