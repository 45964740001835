//COLORS
$color-primary: #5314BC;
$color-primary-blue: #481CCC;
$color-primary-darker: #37148E;
$color-primary-lighter: #A589DD;
$color-primary-light: #D0C4EE;
$color-primary-extra-dark: #1D0E5D;
$color-purple-light: #764FCD;
$color-blue-light: #634BDB;

$color-green-highlight: #3EC89E;
$color-yellow-highlight: #F5C852;

$color-primary-text: #29394B;
$color-text-light: #687283;
$color-white: #ffffff;
$color-white-darker: #F5F4F5;
$color-grey-light: #E7E9EB;
$color-grey-background: #C3C7CD;

$color-green-light: #128C7B;
$color-green-dark: #37B392;


// FONT
$default-font-size: 2rem;
$letter-spacing-default: 0.5px;

//GRID
$grid-width: 100%;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6.4rem;

// BREAKPOINTS


$small-devices-width: 600px;
$phone-width: 900px;
$tablet-width: 1300px;