.u-margin-bottom-regular {
    margin-bottom: 4.8rem;
    @media (max-width: $phone-width) {
        margin-bottom: 2.4rem;
    }
}
.u-margin-bottom-big {
    margin-bottom: 5.6rem;

    @media (max-width: $phone-width) {
        margin-bottom: 4rem;
    }
}
.u-margin-bottom-bigger {
    margin-bottom: 12rem;

    @media (max-width: $phone-width) {
        margin-bottom: 6rem;
    }
}
.u-margin-bottom-extra-big {
    margin-bottom: 13.2rem;
}
.u-margin-bottom-small {
    margin-bottom: 1.6rem;

    @media (max-width: $phone-width) {
        margin-bottom: 8px;
    }
}
.u-margin-bottom-24 {
    margin-bottom: 2.4rem;
}
.u-margin-bottom-32 {
    margin-bottom: 3.2rem;
}


.u-margin-top-extra-large {
    margin-top: 14rem;
    @media (max-width: $phone-width) {
        margin-top: 6rem;
    }
}
.u-margin-top-extra-big {
    margin-top: 10rem;
    @media (max-width: $phone-width) {
        margin-top: 4rem;
    }
}
.u-margin-top-big {
    margin-top: 6.4rem;
    @media (max-width: $phone-width) {
        margin-top: 3.2rem;
    }
}
.u-margin-top-medium {
    margin-top: 3.2rem;
}

.u-margin-right-medium {
    margin-right: 2.4rem;
    @media (max-width: 1024px) {
        margin-right: 1.6rem;
    }
}

.u-padding-top-100 {
    padding-top: 10rem;
    @media (max-width: $phone-width) {
        padding-top: 4rem;
    }
}
.u-padding-top-120 {
    padding-top: 12rem;
}
.u-padding-top-110 {
    padding-top: 11rem;
}
.u-padding-top-140 {
    padding-top: 14rem;
    @media (max-width: $phone-width) {
        padding-top: 6rem;
    }
}

.u-padding-bottom-24 {
    padding-bottom: 2.4rem;
}
.u-padding-bottom-64 {
    padding-bottom: 6.4rem;
    @media (max-width: $phone-width) {
        padding-bottom: 3.2rem;
    }
}
.u-padding-bottom-100 {
    padding-bottom: 10rem;
    @media (max-width: $phone-width) {
        padding-bottom: 3.2rem;
    }
}
.u-padding-bottom-big {
    padding-bottom: 12rem;
    @media (max-width: $phone-width) {
        padding-bottom: 6rem;
    }
}
.u-padding-bottom-140 {
    padding-bottom: 14rem;
    @media (max-width: $phone-width) {
        padding-bottom: 6rem;
    }
}
.u-padding-bottom-160 {
    padding-bottom: 16rem;
    @media (max-width: $phone-width) {
        padding-bottom: 10rem;
    }
}
.u-padding-bottom-230 {
    padding-bottom: 23rem;
}





.u-center-text { 
    text-align: center;
    @media (max-width: $phone-width) {
        text-align: left;
    }
}