.author-date {
    display: inline-flex;
    align-items: center;
    padding-top: 5.6rem;
    vertical-align: middle;
    @media (max-width: $phone-width) {
        padding-top: 3.2rem;
    }
    .author-img-holder {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background: $color-grey-background;
        margin-right: 1.2rem;
        @media (max-width: $phone-width) {
            width: 3.2rem;
            height: 3.2rem;
            margin-right: 8px;
        }
    }
    .author-name,
    .date {
        font-size: 1.8rem;
        line-height: 3.2rem;
        color: $color-white-darker;
        font-weight: 500;
        @media (max-width: $phone-width) {
            font-size: 1.2rem;
            line-height: 1.5rem;
        }
    }
    .author-name {
        font-weight: 700;
        margin-right: 8px;
        @media (max-width: $phone-width) {
            margin-right: 4px;
        }
    }
}

.section-blog-single {
    background: $color-white;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    align-items: center;
    @media (max-width: $phone-width) {
        padding-top: 4rem;
    }

    &__brief {
        width: 74rem;
        @media (max-width: $phone-width) {
            width: auto;
            padding-left: 3rem;
            padding-right: 3rem;
            order: 2;
        }
        .breadcrumbs {
            margin-bottom: 1.6rem;
           
        }
    }

    &__img-holder {
        width: 74rem;
        height: 32rem;
        padding: 3.2rem 0;
        @media (max-width: $phone-width) {
            width: auto;
            order: 2;
            padding-top: 0;
            height: 100%;
            padding-bottom: 8px;
            display: none;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__blog-text {
        width: 74rem;
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        @media (max-width: $phone-width) {
            padding-left: 3rem;
            padding-right: 3rem;
            width: auto;
            order: 2;
        }
        a {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            font-weight: bold;
            cursor: pointer;
            color: $color-primary;
            &:hover {
                text-decoration: underline;
            }
        }
        img {
            width: 100%;
        }
        blockquote p {
            
            font-size: 4rem;
            line-height: 5.2rem;
            font-weight: 800;
            color: $color-primary-blue;
            @media (max-width: $phone-width) {
                font-size: 2.4rem;
                line-height: 3.2rem;
            }

        }
        
        p, ul, li {
            font-size: 2rem;
            line-height: 3.6rem;
            color: $color-primary-text;
            font-weight: 500;
            text-align: left;
            

            @media (max-width: $phone-width) {
                font-size: 1.7rem;
                line-height: 2.7rem;
            }
        }
        li {
            margin-bottom: 2rem;
        }

        h2 {
            text-transform: initial;
            font-size: 2.4rem;
            line-height: 3.2rem;
            @media (max-width: $phone-width) {
                font-size: 2rem;
                line-height: 2.8rem;
            }
        }
        h2 {
            @media (max-width: $phone-width) {
                font-size: 2.8rem;
                line-height: 3.6rem;
            }
        }
        ul {
            margin-left: 4.8rem;
            margin-top: 2rem;

            li::marker {
                color: $color-primary;
                font-size: 2rem;
            }
        }
    }
}

.section-blog-single-t-and-c {
    @media (max-width: $phone-width) {
        padding-top: 6rem;
        
    }
}


.section-author {
    background: $color-white;

    &__container {
        background: $color-white-darker;
        width: 93rem;
        margin: 0 auto;
        display: flex;
        padding: 40px 50px;
        flex-direction: column;
        position: relative;
        overflow: hidden;

        @media (max-width: $tablet-width) {
            width: 80%;
            padding: 30px 30px;
         }

        @media (max-width: $phone-width) {
            width: 100%;
            padding: 30px 30px;
        }

        .headline-5 {
            color: $color-primary-blue;
            margin-bottom: 10px;
            @media (max-width: $phone-width) {
                font-size: 1.4rem;
                line-height: 2.2rem;
            }
        }
        .headline-4 {
            margin-bottom: 30px;
            @media (max-width: $phone-width) {
                font-size: 2.4rem;
                line-height: 3.2rem;
            }
        }
       
        .about {
            width: 75%;
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 20px;

            @media (max-width: $phone-width) {
                width: 55%;
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }
        .socials {
            opacity: 0.5;
        }

        .socials::hover {
            opacity: 1;
        }
        .image-author {
            width: 250px;
            height: 360px;
            display: block;
            position: absolute;
            right: 0px;
            bottom: 0px;

            @media (max-width: $tablet-width) {
                right: -50px;
                bottom: -50px;
            }
        }
    }
}