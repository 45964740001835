.overlay-background-header {
    
    background-color: $color-primary-blue!important;
    background-blend-mode: multiply!important;
    background-size: cover!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    height: 46rem;
    overflow: hidden!important;
    @media (max-width: $phone-width) {
        height: auto;
        background: none;
        background-color: $color-primary-blue;
    }
}
.overlay-background-header-game {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: url('/assets/img/gameSingleCover.png');
    background-color: $color-primary-blue;
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 46rem;
    overflow: hidden;
    @media (max-width: $phone-width) {
        height: auto;
        background: none;
        background-color: $color-primary-blue;
    }
    
}

.header {
    overflow: hidden;
    @media (max-width: $phone-width) {
        overflow: hidden;
    }

    .headline-1, .headline-2 {
        width: 112rem;
        height: 12rem;
        margin: 0 auto;

        @media (max-width: $tablet-width) {
            width: 100%;
            height: auto;
        }        
    }
    
    background-color: $color-primary;
    position: relative;

    &--purple {
        background-color: $color-primary;
    }
    &--blue {
        background-color: $color-primary-blue;
    }
    &--green {
        background-color: $color-green-highlight;
    }
    &--overlay {
        background: transparent;
        // @media (max-width: $phone-width) {
        //     background: $color-primary-blue;
        // }
    }
    
    &__text-box {
        text-align: center;
        padding-top: 2.8rem;
        z-index: 10;
        position: relative;
        

        @media (max-width: $phone-width) {
            text-align: center;
            padding-left: 3rem;
            padding-right: 3rem;
        }
        
        .headline-1 {
            height: auto;
            width: 93rem;
            z-index: 10;
            position: relative;

            @media (max-width: $phone-width) {
                width: auto;
            }
        }

        .game-img-rating {
            .game-rating {
                padding-top: 5.6rem;
                
                @media (min-width: $tablet-width) {
                    padding-top: 0px;
                }

                img {
                    width: 2.2rem;
                    height: 2rem;
                }
            }
            .caption-big {
                font-size: 1.6rem;
                line-height: 2.8rem;
            }
        }

    }
    &__text-box-hero {
        width: 93rem;
        margin: 0 auto;
        margin-bottom: 5.6rem;

        @media (max-width: $phone-width) {
            width: auto;
            text-align: left;
        }
    }
    &__text-holder {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        width: 55rem;
        margin: 0 auto;
        position: relative;
        z-index: 10;

        @media (max-width: $tablet-width) {
            padding-bottom: 30rem;
        }
        @media (max-width: $phone-width) {
            padding-bottom: 24.8rem;
        }
        @media (max-width: $small-devices-width) {
            width: 100%;
            padding: 3.2rem 3rem 24.8rem 3rem;
        }

        .headline-5 {
            text-transform: none;
            font-size: 2.4rem;
            line-height: 3.2rem;

            @media (max-width: $phone-width) {
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }
        .email-link-style {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            &--purple {
                color: $color-primary;
            }
        }
        .email-link {
            cursor: pointer;
        }
    }

    &__button-holder {
        text-align: center;

        @media (max-width: $phone-width) {
            text-align: left;
            margin-bottom: 5rem;
            padding: 0 3rem;
        }
    }

    &__sub-header {

        &__wrapper {
            display: block;
            width: 60%;
            margin: 0 auto;
            
            @media (max-width: $phone-width) {
                width: 100%;
            }
        }

        background-color: $color-primary-darker;
        text-align: center;
        padding: 5.2rem 0rem;
        z-index: 10;
        position: relative;

        @media (max-width: $phone-width) {
            padding: 3.2rem 3rem;
            text-align: left;
        }

        .sub-header__logo {
            width: 18.5rem;
            margin-right: 5px;
            vertical-align: text-bottom;
            margin-bottom: -2px;

            @media (max-width: $phone-width) {
                width: 12.7rem;
                height: 2.6rem;
                vertical-align: baseline;
            }
        }
        .sub-header__text {
            font-size: 2.4rem;
            line-height: 4rem;
            font-weight: 500;
            color: $color-white;
            
            @media (max-width: $phone-width) {
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
            
        }
    }

    &__circle {
        &--big {
            position: absolute;
            background-color: $color-primary-darker;
            border-radius: 50%;
            width: 24rem;
            height: 24rem;
            top: 9.6rem;
            right: -17.7rem;

            @media (max-width: $phone-width) {
                width: 10rem;
                height: 10rem;
                right: 5.2rem;
                top: 24.9rem;
            }
        }
        &--medium {
            position: absolute;
            background-color: $color-primary-darker;
            border-radius: 50%;
            width: 15.4rem;
            height: 15.4rem;
            top: 33.4rem;
            right: -33.1rem;

            @media (max-width: $phone-width) {
                width: 6.4rem;
                height: 6.4rem;
                right: -1rem;
                top: 34.9rem;
            }
        }
        &--small {
            position: absolute;
            background-color: $color-primary-darker;
            border-radius: 50%;
            width: 12rem;
            height: 12rem;
            top: 48.8rem;
            right: -15.9rem;

            @media (max-width: $phone-width) {
                width: 5.2rem;
                height: 5.2rem;
                right: 6rem;
                top: 41.3rem;
            }
        }

    }
    .rectangle-about {
        position: absolute;
        background: $color-green-highlight;

        &--first {
            width: 26.5rem;
            height: 26.6rem;
            bottom: 14rem;
            right: -37rem;
            padding: 2.4rem 3.2rem 2.8rem 3.2rem;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            @media (max-width: $tablet-width) {
                width: 55rem;
                height: auto;
                right: 0;
                left: 0;
                margin: 0 auto;
                bottom: 12rem;
                gap: 8px;
                padding: 2rem;
            }
            @media (max-width: $phone-width) {
                width: 85%;
                bottom: 6rem;
            }
            .rectangle-question {
                font-weight: 700;
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: $color-primary-blue;
                text-transform: uppercase;
                @media (max-width: $phone-width) {
                    font-size: 1.2rem;
                    line-height: 2rem;
                }
            }
            .rectangle-answer {
                font-size: 1.8rem;
                line-height: 2.6rem;
                font-weight: 700;
                color: $color-primary-blue;
                @media (max-width: $tablet-width) {
                    br {
                        display: none;
                    }
                }
                @media (max-width: $phone-width) {
                    font-size: 1.4rem;
                    line-height: 2rem;
                }
            }
        }
        &--second {
            width: 9.5rem;
            height: 9.5rem;
            bottom: 4.5rem;
            right: -46.5rem;
            @media (max-width: $tablet-width) {
                display: none;
                bottom: 13rem;
                width: 8rem;
                height: 8rem;
                right: 23.6rem;
            }
        }
        &--third {
            width: 4.5rem;
            height: 4.5rem;
            bottom: 0;
            right: -37rem;
            @media (max-width: $phone-width) {
                display: none;
                bottom: 10.1rem;
                right: 31.6rem;
                width: 2.9rem;
                height: 2.9rem;
            }
        }
        &--yellow {
            background-color: $color-yellow-highlight;
        }
    }

    .headline-pricing-mobile {
        display: none;
        @media (max-width: $phone-width) {
            display: block;
        }
    }

    .headline-pricing-desktop {
        @media (max-width: $phone-width) {
            display: none;
        }
    }
}
.header-about {
    &__circle {
        &--big {
            position: absolute;
            background-color: $color-green-highlight;
            border-radius: 50%;
            width: 24rem;
            height: 24rem;
            top: -8.4rem;
            left: -50.5rem;
            @media (max-width: $tablet-width) {
                display: none;
            }
        }
        &--medium {
            position: absolute;
            background-color: $color-green-highlight;
            border-radius: 50%;
            width: 15.4rem;
            height: 15.4rem;
            top: 15.4rem;
            left: -26.5rem;
            @media (max-width: $tablet-width) {
                display: none;
            }
        }
        &--small {
            position: absolute;
            background-color: $color-green-highlight;
            border-radius: 50%;
            width: 12rem;
            height: 12rem;
            top: 30.8rem;
            left: -40.3rem;
            @media (max-width: $tablet-width) {
                display: none;
            }
        }
        &--yellow {
            background-color: $color-yellow-highlight;
        }

    }
}