.section-blog-articles {
    background: $color-white;

    @media (max-width: $phone-width) {
        padding: 4rem 3rem 6rem 3rem;
    }
    
    .blog-articles-holder {
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
        @media (max-width: $tablet-width) {
            padding-left: 6rem;
            padding-right: 6rem;
        }
        @media (max-width: $phone-width) {
            gap: 3.2rem;
            padding-left: 0;
            padding-right: 0;
        }

        .blog-article {
            width: 93rem;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 3.6rem;
            padding-bottom: 4.8rem;
            border-bottom: 1px solid $color-grey-light;
            @media (max-width: 1050px) {
                width: 100%;
            }
            @media (max-width: $phone-width) {
                width: 100%;
                gap: 3.2rem;
                padding-bottom: 3.2rem;
            }

            &:last-child {
                border-bottom: none;
            }
    
            &__title-author-date {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                @media (max-width: $phone-width) {
                    gap: 1.2rem;
                }
    
                .author-date {
                    display: flex;
                    align-items: center;
                    padding-top: 0;
    
                    .author-img-holder {
                        width: 4rem;
                        height: 4rem;
                        border-radius: 50%;
                        background: $color-grey-background;
                        margin-right: 1.2rem;
                        @media (max-width: $phone-width) {
                            width: 3.2rem;
                            height: 3.2rem;
                            margin-right: 8px;
                        }
                    }
                    .author-name,
                    .date {
                        font-size: 20px;
                        line-height: 32px;
                        color: $color-primary-text;
                        font-weight: 500;
                        @media (max-width: $phone-width) {
                            font-size: 1.2rem;
                            line-height: 1.5rem;
                        }
                    }
                    .author-name {
                        font-weight: 700;
                        margin-right: 8px;
                        @media (max-width: $phone-width) {
                            margin-right: 4px;
                        }
                    }
                }
            }
            .short-text-img-holder {
                display: flex;
                gap: 2rem;
    
                .short-text-btn-holder {
                    display: flex;
                    flex-direction: column;
                    gap: 4rem;
                    width: 55rem;
                    @media (max-width: $phone-width) {
                        width: 100%;
                        gap: 3.2rem;
                    }

                    p {
                        height: 18rem;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        @media (max-width: $phone-width) {
                            height: auto;
                        }
                    }
                    .btn-holder {
                        text-align: left;
                    }
                }
                .blog-img-holder {
                    background-color: $color-grey-background;
                    width: 36rem;
                    height: 28rem;
                    @media (max-width: $phone-width) {
                        display: none;
                    }
                }
            }
        }
    }
    .load-more-articles {
        padding: 1.8rem 6.8rem 1.8rem 6.4rem;
        @media (max-width: $phone-width) {
            width: 100%;
        }
    }   
}