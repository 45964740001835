.cookie-policy-banner {
    background-color: $color-primary-text;
    display: none;
    flex-direction: row;
    gap: 4.8rem;
    padding: 4.8rem 0;
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    justify-content: center;

    @media (max-width: $phone-width) {
        flex-direction: column;
        gap: 2.4rem;
        padding: 3rem 2.4rem;
    }

    .footer-text {
        width: 93.5rem;
        
        @media (max-width: $phone-width) {
            width: 100%;
        }

        .email-link-style {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}