.section-about-our-service {    
    background: $color-white;
    @media (max-width: $tablet-width) {
        padding: 0 6rem;
    }
    @media (max-width: $phone-width) {
        padding: 0 3rem;
    }

    &__img-text-holder {
        width: 93rem;
        position: relative;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 8rem;
        @media (max-width: 1050px) {
            width: 100%;
        }
        @media (max-width: $phone-width) {
            width: 100%;
            gap: 4rem;
        }
        

        .img-holder {
            display: block;
            margin: auto;
            max-width: none;
            width: 100vw;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            height: auto;
            text-align: center;
            top: -10rem;
            
            @media (max-width: $phone-width) {
                padding-left: 3rem;
                padding-right: 3rem;
                top: -6.8rem;
                width: 450px;
            }
            @media (max-width: $small-devices-width) {
                width: 330px;
            }
        }
        .platform-img-holder {
            img {
                width: 70.2rem;
                height: 58.6rem;
                @media (max-width: $phone-width) {
                    height: 100%;
                    width: 100%;
                    //height: 24.6rem;
                }
            }
            & + .desktop-table-28 {
                margin-top: 56.6rem;
                @media (max-width: $phone-width) {
                    margin-top: 28.9rem;
                }
                @media (max-width: $small-devices-width) {
                    margin-top: 21.9rem;
                }
            }
            
            
        }
        .datafeed-img-holder {
            img {
                width: 75rem;
                height: 46.2rem;
                @media (max-width: $phone-width) {
                    height: 100%;
                    width: 100%;
                }
                
            }
            & + .desktop-table-28 {
                margin-top: 44.2rem;
                @media (max-width: $phone-width) {
                    margin-top: 19.7rem;
                }
                @media (max-width: $small-devices-width) {
                    margin-top: 14.7rem;
                }
            }
            
        }
        .reports-img-holder {
            img {
                width: 75rem;
                height: 46.2rem;
                @media (max-width: $phone-width) {
                    height: 100%;
                    width: 100%;
                    //height: 24.6rem;
                }
            }
            & + .desktop-table-28 {
                margin-top: 44.2rem;
                @media (max-width: $phone-width) {
                    margin-top: 19.7rem;
                }
                @media (max-width: $small-devices-width) {
                    margin-top: 14.7rem;
                }
            }
            
        }
        .slot-raing-img-holder {
            &--desktop {
                width: 113rem;
                height: 36rem;
                @media (max-width: $tablet-width) {
                    width: 87rem;
                    height: auto;
                }
                @media (max-width: $phone-width) {
                    display: none;
                }
            }
            &--mobile {
                display: none;
                @media (max-width: $phone-width) {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            
            & + .desktop-table-28 {
                margin-top: 34rem;
                @media (max-width: $phone-width) {
                    margin-top:  37rem;
                }
                @media (max-width: $small-devices-width) {
                    margin-top: 26rem;
                }
            }
            
        }

        
    }
    &__features {
        width: 93rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 8rem;
        @media (max-width: 1050px) {
            width: 100%;
        }
        @media (max-width: $phone-width) {
            gap: 4rem;
        }
        
        &--item {
            display: flex;
            gap: 2rem;

            .headline-text {
                display: flex;
                flex-direction: column;
                gap: 3.2rem;
                width: 100%;
                @media (max-width: $phone-width) {
                    width: 100%;
                    gap: 1.6rem;
                }
            }
            .img-holder {
                display: none;
                width: 36rem;
                height: 30rem;
                background: $color-grey-background;
                @media (max-width: $phone-width) {
                    display: none;
                }
            }
        }
    }
}