.section-pricing {
    background: $color-green-highlight;
    position: relative;
    .header__circle--big {
        top: 1.6rem;
        right: -9.2rem;
        background: $color-primary;
        @media (max-width: $tablet-width) {
            display: none;
        }
    } 
    .header__circle--medium {
        top: 25.4rem;
        right: -24.6rem;
        background: $color-primary;
        @media (max-width: $tablet-width) {
            display: none;
        }
    }
    .header__circle--small {
        top: 40.8rem;
        right: -7.4rem;
        background: $color-primary;
        @media (max-width: $tablet-width) {
            display: none;
        }
    }
    

    &__programs {
        z-index: 10;
        margin: 0 auto;
        position: relative;

        @media (max-width: $phone-width){
            padding-bottom: 0;
        }

        &--program {
            width: 112rem;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 2.4rem;
            position: relative;

            @media (max-width: $tablet-width) {
                width: auto;
            }
            @media (max-width: $phone-width) {
                width: 100%;
                padding-top: 0;
            }

            .headline-5 {
                text-align: center;
                text-transform: capitalize;
                cursor: pointer;
                transition: all 0.1s;
                @media (max-width: $phone-width) {
                    font-size: 2rem;
                    line-height: 2.4rem;
                    padding: 4rem 3rem 0 3rem;
                }

                .active {
                    color: $color-primary;
                    transition: all 0.1s;
                    border-bottom: 3px solid $color-primary;
                    padding-bottom: 2px;
                }
                .headline-pricing-desktop {
                    @media (max-width: $phone-width) {
                        display: none;
                    }
                }
            }
        }
        .pricing-tables {
            display: flex;
            background: $color-white;
            padding: 5.6rem 9.5rem;
            z-index: 10;

            @media (max-width: $phone-width) {
                flex-direction: column;
                padding: 4rem 3rem; 

                .pricing-button-holder {
                    .btn {
                        width: 100%;
                    }
                }
            }

            &--monthly {
                flex-direction: column;
                gap: 4.8rem;
                @media (max-width: $phone-width) {
                    flex-direction: column;
                    padding-right: 0;

                    .desktop-table-18 {
                        padding-right: 3rem;
                    }
                    .pricing-button-holder {
                        padding-right: 3rem;
                    }
                }
                
            }
            &--one-time {
                flex-direction: row;
                gap: 2rem;
                @media (max-width: $phone-width) {
                    flex-direction: column;
                    gap: 6rem;

                    .program-price-holder {
                        flex-direction: row !important;
                        align-items: baseline;
                        gap: 8px !important;
                    }
                }

                .pricing-table {
                    gap: 0rem;
                    width: 45.5rem;

                   @media (max-width: $tablet-width) {
                       width: 40rem;
                   }
                    @media (max-width: $phone-width) {
                        width: 100%;
                    }
                    
                    &:first-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }

                    .pricing-programs-holder .desktop-table-18 {
                        width: 26.5rem;
                        @media (max-width: $phone-width) {
                            width: 100%;
                        }
                    }
                }
            }
            &--monthly-report {
                @media (max-width: $phone-width) {
                    padding-bottom: 6rem;
                }
                .pricing-table {
                    gap: 0;
                    &:first-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                    .pricing-programs-holder {
                        .desktop-table-18 {
                            width: 100%;
                            
                        }
                    }
                }
            }
        }



        .pricing-table {
            display: flex;
            flex-direction: column;
            gap: 3.2rem;

            &:first-child {
                border-bottom: 1px solid $color-grey-light;
                padding-bottom: 4.8rem;
            }

            @media (max-width: $phone-width) {
                .caption-big {
                    font-size: 1.2rem;
                    line-height: 2rem;
                }
            }

            &--header {
                display: flex;
                flex-direction: row;
                gap: 1.2rem;
                align-items: center;

                .header-img-holder {
                    width: 2.4rem;
                    height: 2.4rem;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .headline-5 {
                    text-transform: capitalize;

                    @media (max-width: $phone-width) {
                        padding: 0;
                    }
                }
            }

            .pricing-programs-holder {
                display: flex;
                gap: 2rem;

                @media (max-width: $phone-width) {
                    flex-direction: column;
                }
                .price-program-table-mobile {
                    display: none;
                    @media (max-width: $phone-width) {
                        display: flex;
                        flex-direction: column;
                        gap: 3.2rem;
                    }
                }

                .desktop-table-18 {
                    width: 36rem;

                    @media (max-width: $phone-width) {
                        width: 100%;
                    }
                }

                .price-programs {
                    display: flex;
                    gap: 2rem;
                    @media (max-width: $phone-width) {
                        overflow-x: auto;
                        gap: 1rem;
                        &::-webkit-scrollbar {
                            display: none;
                          }
                    }
                    .price-program {
                        display: flex;
                        flex-direction: column;
                        gap: 1.6rem;
                        width: 17rem;
                        @media (max-width: $phone-width) {
                            width: 15rem;
                            &:last-child {
                                margin-right: 3rem;

                            }
                        }
                    }
                    .program-type {
                        padding: 6px 0px;
                        width: 12rem;
                        font-weight: bold;
                        font-size: 1.4rem;
                        // line-height: 1.8rem;
                        color: $color-white-darker;
                        text-align: center;
                        text-transform: uppercase;
                        border-radius: 30px;

                        @media (max-width: $phone-width) {
                            padding: 2px 2.5rem;
                            font-size: 1.2rem;
                            line-height: 2rem;
                        }

                        &--light-purple {
                            background: $color-purple-light;
                        }
                        &--dark-purple {
                            background: $color-primary;
                        }
                        &--primary-blue {
                            background: $color-primary-blue;
                        }
                        
                    }
                    .program-price-holder {

                        .hide {
                            display: none;
                        }
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        @media (max-width: $phone-width) {
                            gap: 0px;
                        }

                        .program-price {
                            font-weight: 500;
                            font-size: 5rem;
                            line-height: 5.9rem;
                            color: $color-primary-text;
                            @media (max-width: $phone-width) {
                                font-size: 3.6rem;
                                line-height: 4.4rem;
                            }
                            span {
                                font-size: 2rem;
                                line-height: 2.4rem;
                                @media (max-width: $phone-width) {
                                    white-space: nowrap;
                                }
                            }
                        }
                        .program-price-per-year {
                            font-weight: 500;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            color: $color-text-light;
                            @media (max-width: $phone-width) {
                                font-size: 1.4rem;
                                line-height: 2rem;
                                white-space: nowrap;
                                width: 15rem;
                            }
                        }
                    }
                }
                .price-programs-desktop {
                    @media (max-width:$phone-width) {
                        display: none;
                    }
                }
                .price-programs-mobile {
                    display: none;
                    @media (max-width:$phone-width) {
                        display: flex;
                    }
                }
            }
            .programs-benefit-table-holder {
                display: flex;
                flex-direction: column;
                gap: 2.4rem;
                

                .programs-benefit-table {
                    display: flex;
                    flex-direction: column;
                    gap: 2.4rem;

                    .programs-benefit-table-item {
                        display: grid;
                        grid-template-columns: repeat(5, 1fr);
                        gap: 2rem;

                        p:first-child {
                            grid-column: 1/3;
                        }
                    }
                    .programs-benefit-table-item:first-child {
                        padding: 8px 0;
                        @media (max-width: $phone-width) {
                            display: none;
                        }
                    }
                }
            }
            .programs-benefit-table-holder-desktop {
                @media (max-width:$phone-width) {
                    display: none;
                }
            }
            .programs-benefit-table-holder-mobile {
                display: none;
                @media (max-width:$phone-width) {
                    display: flex;
                    padding-right: 3rem;
                }
            }
        }
        
    }

}

.section-faq {
    background: $color-white;

    &__container {
        width: 93rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 5.6rem;
        @media (max-width: $tablet-width) {
            width: 100%;
            padding-left: 8.5rem;
            padding-right: 8.5rem; 
         }
        @media (max-width: $phone-width) {
            width: 100%;
            padding: 0rem 3rem 6rem 3rem;
            gap: 3.2rem;

            &--pricing {
                padding-top: 4rem;
            }
        }

        .headline-2 {
            @media (max-width: $phone-width) {
                font-size: 2.4rem;
                line-height: 3.2rem;
            }
        }

        .faqs {
            display: flex;
            flex-direction: column;
            gap: 3.2rem;

            @media (max-width: $phone-width) {
                gap: 2.4rem;
            }

            .faq {
                
                display: flex;
                flex-direction: column;
                padding-bottom: 3.2rem;
                border-bottom: 1px solid $color-grey-light;

                .email-link-style {
                    color: inherit;
                    font-size: inherit;
                    line-height: inherit;
                    font-weight: bold;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                    &--purple {
                        color: $color-primary;
                    }
                }
                .email-link {
                    cursor: pointer;
                }
                @media (max-width: $phone-width) {
                    padding-bottom: 2.4rem;
                }

                &:last-child {
                    padding-bottom: 0;
                    border-bottom: none;
                }

                .faq-question-holder {
                    display: flex;
                    flex-direction: row;
                    gap: 6.4rem;
                    justify-content: space-between;
                    align-items: flex-start;
                    transition: all .1s ease-in-out;
                    cursor: pointer;

                    &:hover, &:active {
                       .headline-4 {
                           color: $color-primary-text;
                       }
                       .faq-show-hide-answer path {
                           fill: $color-primary-text;
                       }
                    }

                    @media (max-width: $phone-width) {
                        gap: 1.6rem;
                    }

                    .faq-show-hide-answer {
                        width: 1.6rem;
                        height: 4rem;
                        cursor: pointer;
                        transition: all .1s ease-in-out;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (max-width: $phone-width) {
                            height: 2.8rem;
                        }
                    }
                }
                .faq-asnwer-holder {
                    visibility: hidden;
                    height: 0;
                    opacity: 0;
                    transition:  padding 0.1s ease-out, opacity 0.1s ease 0.1s;

                    .backgrounds-and-text {
                        @media (max-width: $phone-width) {
                            font-size: 1.4rem;
                            line-height: 2rem;
                        }
                    }
                }
            }
        }
    }
}

