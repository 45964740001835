@charset "UTF-8";

@font-face {
  font-family: "Gilroy";
  src:url("/assets/fonts/Gilroy-Regular/Gilroy-Regular.eot");
  src:
    url("/assets/fonts/Gilroy-Regular/Gilroy-Regular.woff2") format("woff"),
    url("/assets/fonts/Gilroy-Regular/Gilroy-Regular.ttf") format("truetype"),
    url("/assets/fonts/Gilroy-Regular/Gilroy-Regular.svg") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;

}
@font-face {
    font-family: "Gilroy";
    src:url("/assets/fonts/Gilroy-Bold/Gilroy-Bold.eot");
    src:
      url("/assets/fonts/Gilroy-Bold/Gilroy-Bold.woff2") format("woff"),
      url("/assets/fonts/Gilroy-Bold/Gilroy-Bold.ttf") format("truetype"),
      url("/assets/fonts/Gilroy-Bold/Gilroy-Bold.svg") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Gilroy";
    src:url("/assets/fonts/Gilroy-ExtraBold/Gilroy-ExtraBold.eot");
    src:
      url("/assets/fonts/Gilroy-ExtraBold/Gilroy-ExtraBold.woff2") format("woff"),
      url("/assets/fonts/Gilroy-ExtraBold/Gilroy-ExtraBold.ttf") format("truetype"),
      url("/assets/fonts/Gilroy-ExtraBold/Gilroy-ExtraBold.svg") format("svg");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Gilroy";
    src:url("/assets/fonts/Gilroy-Light/Gilroy-Light.eot");
    src:
      url("/assets/fonts/Gilroy-Light/Gilroy-Light.woff2") format("woff"),
      url("/assets/fonts/Gilroy-Light/Gilroy-Light.ttf") format("truetype"),
      url("/assets/fonts/Gilroy-Light/Gilroy-Light.svg") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Gilroy";
    src:url("/assets/fonts/Gilroy-Medium/Gilroy-Medium.eot");
    src:
      url("/assets/fonts/Gilroy-Medium/Gilroy-Medium.woff2") format("woff"),
      url("/assets/fonts/Gilroy-Medium/Gilroy-Medium.ttf") format("truetype"),
      url("/assets/fonts/Gilroy-Medium/Gilroy-Medium.svg") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }


::selection {
    color: $color-white-darker;
    background-color: $color-purple-light;
}

body {
    font-family: "Gilroy", --apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 500;
    line-height: 1.8;
    color: $color-primary-text;
    
}

.headline-1 {
    color: $color-white-darker;
    font-size: 6rem;
    font-weight: 800;
    line-height: 7.2rem;
    
    
    &--highlight {
        color: $color-primary-light;
        
    }
    &--purple {
        color: $color-primary;
    }
    &--blue {
        color:  $color-primary-blue;
    }

    @media (max-width: $phone-width) {
        font-size: 3.6rem;
        line-height: 4.4rem;
    }
}

.headline-2 {
    font-size: 4.8rem;
    line-height: 6rem;
    font-weight: 800;

    @media (max-width: $phone-width) {
        font-size: 3.2rem;
        line-height: 4rem;
    }

    &--white {
        color: $color-white-darker;
    }
    &--green {
        color: $color-green-highlight;
    }
    &--yellow {
        color: $color-yellow-highlight;
    }
    &--purple {
        color: $color-primary;
    }
}

.headline-3 {
    font-size: 4rem;
    line-height: 5.2rem;
    font-weight: 500;
    @media (max-width: $phone-width) {
        font-size: 2.4rem;
        line-height: 3.2rem;
    }

    &--bold {
        font-weight: 800;
    }
    &--dark {
        color: $color-primary-text;
    }
    &--purple {
        color: $color-primary;
    }
    &--blue {
        color: $color-primary-blue;
    }
}

.headline-4 {
    font-weight: 800;
    font-size: 3.2rem;
    line-height: 4rem;
    color: $color-primary-text;

    @media (max-width: $phone-width) {
        font-size: 2rem;
        line-height: 2.8rem;
    }

    &--white {
        color: $color-white-darker;
    }
    &--purple {
        color: $color-primary;
    }
    &--yellow {
        color: $color-yellow-highlight;
    }
    .highlight-green {
        color: $color-green-highlight;
    }
}

.headline-5 {
    font-size: 2rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    font-weight: 800;

    @media (max-width: $phone-width) {
        font-size: 1.2rem;
        line-height: 2rem;
    }

    &--purple {
        color: $color-primary;
    }
    &--white {
        color: $color-white;
    }
    &--light-purple {
        color: $color-primary-light;
    }
    &--dark {
        color: $color-primary-text;
    }
    &--blue {
        color:  $color-primary-blue;
    }
    &--light-green {
        color: $color-green-light;
    }
}

.backgrounds-and-text {
    font-size: 2rem;
    line-height: 3.6rem;
    color: $color-primary-text;
    font-weight: 500;
    text-align: left;
    

    @media (max-width: $phone-width) {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    &--white {
        color: $color-white;
    }

    &--bold {
        font-weight: 800;
    } 
}

.desktop-table-28 {
    font-size: 2.8rem;
    line-height: 4.8rem;
    font-weight: 500;
    color: $color-primary-text;
    
    a {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: bold;
        cursor: pointer;
        color: $color-primary;
        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: $phone-width) {
        font-size: 2rem;
        line-height: 2.8rem;
    }
}
.desktop-table-18 {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.8rem;
    color: $color-primary-text;

    @media (max-width: $phone-width) {
        font-size: 1.4rem;
        line-height: 2rem;
    }
}
.desktop-table-16 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $color-primary-text;
    font-weight: 500;
    
    &--bold {
        font-weight: 700;
        display: flex;
        align-items: center;
        @media (max-width: $phone-width) {
            align-items: inherit;
        }
        img {
            margin-left: 4px;
        }
    }

    &--purple {
        color: $color-primary;
    }
    &--blue {
        color: $color-primary-blue;
    }
}

.caption-big {
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 500;

    &--bold {
        font-weight: 700;
    }
    &--white {
        color: $color-white-darker;
    }
    &--light-gray {
        color: $color-text-light;
    }

    @media (max-width: $phone-width) {
        font-size: 8px;
        line-height: 1rem;
    }

}

.footer-text {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 500;
    color: $color-white;

    &--bold {
        font-weight: 700;
    }
}
