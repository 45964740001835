.section-get-to-know-us {
    background-color: $color-primary-darker;
    display: flex;
    flex-direction: column;
    gap: 10rem;
    margin: 0 auto;
    position: relative;
    align-items: center;

    @media (max-width: $tablet-width) {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    @media (max-width: $phone-width) {
        padding-left: 3rem;
        padding-right: 3rem;
        gap: 6rem;
    }

    &__text-img-holder {
        display: flex;
        gap: 9.5rem;
        width: 111rem;
        @media (max-width: $tablet-width) {
            width: 100%;
            gap: 4rem;
            justify-content: space-between;
        }
        @media (max-width: $phone-width) {
            width: 100%;
        }

        .casinolytics-symbol-holder-desktop {
            width: 45.2rem;
            height: 45.2rem;
            
            @media (max-width: $phone-width) {
                display: none;
            }
        }
    }

    &__about-C {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        width: 55rem;
        @media (max-width: $tablet-width) {
            width: auto;
        }
        @media (max-width: $phone-width) {
            width: 100%;
            gap: 2.4rem;
        }
        .headline-1 {
            margin-bottom: 1.2rem;
            @media (max-width: $phone-width) {
                width: 50%;
                margin-bottom: 0;
            }
        }
        .casinolytics-symbol-holder-mobile {
            display: none;
    
            @media (max-width: $phone-width) {
                display: block;
                height: 16.2rem;
                width: 16.2rem;
                margin: 0 auto;
                margin-bottom: 1.6rem;
                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
    &__who-we-are {
        width: 111rem;
        @media (max-width: $tablet-width) {
            width: 100%;
        }
        

        .who-we-are__item {
            display: flex;
            width: 74rem;
            flex-direction: column;
            gap: 2.4rem;
            padding: 3.2rem 0;
            border-bottom: 1px solid rgba($color-primary-light, 0.08);
            @media (max-width: $tablet-width) {
                width: 100%;
            }
            @media (max-width: $phone-width) {
                gap: 1.2rem;
                padding: 2.4rem 0;
            }
            h4 {
                @media (max-width: $phone-width) {
                    font-size: 2.4rem;
                    line-height: 3.2rem;
                }
            }
            p {
                width: 74rem;
                @media (max-width: $phone-width) {
                    font-size: 1.4rem;
                    line-height: 2rem;
                    width: 100%;
                }
            }
        }
        .who-we-are__item:first-child {
            padding-top: 0px;
        }
        .who-we-are__item:last-child {
            padding-bottom: 0px;
            border-bottom: none;
        }
    }
}
.section-socials {
    background: $color-primary;
    position: relative;
    @media (max-width: $tablet-width) {
        padding-right: 6rem;
        padding-left: 6rem;
    }
    @media (max-width: $phone-width) {
        padding: 4rem 3rem 6rem 3rem;
    }
    .row {
        gap: 11.5rem;
        align-items: flex-start;
        @media (max-width: $tablet-width) {
            gap: 4rem;
            justify-content: flex-end;
        }
        @media (max-width: $phone-width) {
            flex-direction: column;
            gap: 6rem;
            padding-bottom: 0;
        }
        
    }
    &__social-networks-list {
        display: flex;
        flex-direction: column;
        z-index: 10;
        position: relative;
        @media (max-width: $phone-width) {
            order: 2;
            padding-top: 0;
            gap: 8px;
        }

        &--item {
            font-size: 7rem;
            line-height: 8.6rem;
            font-weight: 800;
            color: $color-white-darker;
            text-align: right;
            z-index: 10;
            transition: all .1s;

            &:hover {
                color: $color-primary-light;
            }
            @media (max-width: $phone-width) {
                text-align: left;
                font-size: 4rem;
                line-height: 5.2rem;
            }
        }
    }
    &__text {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        width: 55rem;
        @media (max-width: $tablet-width) {
            width: 47rem;
        }
        @media (max-width: $phone-width) {
            width: 100%;
            order: 1;
        }

        .headline-1 {
            margin-bottom: 1.6rem;
            @media (max-width: $phone-width) {
                margin-bottom: 0;
                width: 50%;
            }
        }
        .email-link-style {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            &--purple {
                color: $color-primary;
            }

        }
        .email-link {
            cursor: pointer;
        }
    }

    .socials-icon {
        position: absolute;
        z-index: 1;
        &--twitter {
            top: 0rem;
            right: 22.8rem;
            width: 20rem;
            height: 16.4rem;
            @media (max-width: $phone-width) {
                width: 16.4rem;
                height: 13.4rem;
                top: 1.4rem;
                left: 16.5rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &--instagram {
            top: 19.8rem;
            right: 29.6rem;
            width: 15.6rem;
            height: 15.6rem;
            @media (max-width: $phone-width) {
                width: 12.8rem;
                height: 12.8rem;
                top: 5.4rem;
                left: 2.95rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &--linekdin {
            top: 37.4rem;
            right: 42.8rem;
            width: 7.8rem;
            height: 8rem;
            @media (max-width: $phone-width) {
                width: 6.4rem;
                height: 6.5rem;
                top: 19.8rem;
                left: -1.45rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &--discord {
            top: 40.2rem;
            right: 26.6rem;
            width: 13rem;
            height: 9.8rem;
            @media (max-width: $phone-width) {
                width: 9.6rem;
                height: 7.2rem;
                top: 21.4rem;
                left: 6.55rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &--twitch {
            top: 48.1rem;
            right: 8.6rem;
            width: 14.8rem;
            height: 16rem;
            @media (max-width: $phone-width) {
                width: 11.2rem;
                height: 13.2rem;
                top: 17.2rem;
                left: 18.55rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.section-how-technology-work {
    background: $color-primary-blue;

    @media (max-width: $phone-width) {
        padding: 4rem 3rem;
    }

    &__text-holder {
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
        width: 55rem;
        margin: 0 auto;
        margin-bottom: 8rem;
        @media (max-width: $phone-width) {
            width: 100%;
            gap: 2rem;
            margin-bottom: 4rem;

            .headline-2 {
                font-size: 2.8rem;
                line-height: 3.6rem;
            }
        }

        .backgrounds-and-text {
            text-align: center;
            @media (max-width: $phone-width) {
                text-align: left;
            }
        }
    }
    &__steps {
        width: 93rem;
        margin: 0 auto;
        
        @media (max-width: $tablet-width) {
            width: auto;
            padding: 0 6rem;
        }
        @media (max-width: $phone-width) {
            width: 100%;
            padding: 0;
        }

        .step {
            display: flex;
            flex-direction: column;
            gap: 2.4rem;
            padding: 3.2rem 0;
            border-bottom: 1px solid rgba($color-primary-light, 0.16);
            position: relative;
            &:first-child {
                padding-top: 0px;

                .steps-img-holder {
                    top: 0;
                }
            }
            &:last-child {
                padding-bottom: 0px;
                border-bottom: none;
            }
            @media (max-width: $phone-width) {
                padding: 2.4rem 0;
                gap: 2rem;

                .headline-4 {
                    width: 17.9rem;
                    height: 5.6rem;
                    display: flex;
                    align-items: center;
                }
            }

            p {
                width: 74rem;
                @media (max-width: $tablet-width) {
                    width: 100%;
                }
                @media (max-width: $phone-width) {
                    width: 100%;
                }
            }
        }
        .steps-img-holder {
            position: absolute;
            top: 3.2rem;
            right: 0;
            @media (max-width: $phone-width) {
                top: 2.4rem;
                width: 6.4rem;
                height: 5.6rem;
            }

            img {
                vertical-align: top;
                width: 100%;
                height: 100%;
            }
        }
    }
}