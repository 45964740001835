.footer {
    background: $color-primary;
    position: relative;

    .row {
        padding-top: 10rem;
        gap: 11.5rem;

        @media (max-width: $tablet-width) {
            padding-left: 6rem;
            padding-right: 6rem;
            justify-content: space-around;
            gap: 4rem;
        }

        @media (max-width: $phone-width) {
            flex-direction: column;
            gap: 6rem;
            padding: 3rem;
            padding-bottom: 6rem;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
    a:link,
    a:visited,
    a:hover,    
    a:active {
        color: $color-white;
    }
    .logo-img-holder-mobile {
        display: none;
        @media (max-width: $phone-width) {
            display: block;
            width: 21.4rem;
            height: auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__left {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        max-width: 38rem;

        @media (max-width: $phone-width) {
            order: 2;
        }
        .logo-img-holder {
            @media (max-width: $phone-width) {
                display: none;
            }
        }
        .terms-and-policy {
            display: flex;
            gap: 2.4rem;
            @media (max-width: $phone-width) {
                flex-direction: column;
            }

            .TandC {
                display: flex;
                gap: 4px;
                justify-content: space-between;
                @media (max-width: $phone-width) {
                    order: 2;
                    justify-content: flex-start;
                }
            }
        }
    }
    &__right {
        display: flex;
        gap: 6.4rem;
        @media (max-width: $tablet-width) {
            gap: 1.2rem 3.2rem;
            flex-direction: column;
            flex-wrap: wrap;
            height: 34rem;
            width: 43rem;
        }
        @media (max-width: $phone-width) {
            order: 1;
            flex-direction: row;
            width: 100%;
            height: auto;
            flex-wrap: wrap;
            gap: 2.4rem;
        }
        .column__single {
            display: flex;
            flex-direction: column;
            gap: 1.2rem;

            @media (max-width: $phone-width) {
                &:first-child, &:last-child {
                    width: 100%;
                }
    
            }

            .column-header {
                font-size: 1.4rem;
                line-height: 1.8rem;
                font-weight: 700;
                text-transform: uppercase;
                color: $color-primary-light;
                @media (max-width: $phone-width) {
                    font-size: 1.2rem;
                    line-height: 1.5rem;
                }
            }
            .headline-5 {
                text-transform: capitalize;
                cursor: pointer;
                @media (max-width: $phone-width) {
                    font-size: 2rem;
                    line-height: 2.4rem;
                }
            }
        }
        .column__double {
            display: flex;
            flex-direction: column;
            gap: 2.4rem;
            @media (max-width: $phone-width) {
                width: 45%;
            }
        }
    }   
}