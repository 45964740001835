.navigation {
    width: 100%;
    padding: 4.8rem 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $tablet-width) {
        padding: 4.8rem 2.4rem;
    }
    @media (max-width: $phone-width) {
        overflow: hidden;
        padding: 2rem;
    }

    &--purple {
        background-color: $color-primary;
    }
    &--blue {
        background-color: $color-primary-blue;
    }
    &--green {
        background-color: $color-green-highlight;
    }
    &--overlay {
        background-color: transparent;
        position: relative;
        // @media (max-width: $phone-width) {
        //     background: $color-primary-blue;
        // }
    }

    &__logo-box {
        display: inline;
        @media (max-width: $phone-width) {
            display: none;
        }
    }
    &__logo-box-mobile {
        display: none;
        @media (max-width: $phone-width) {
            display: inline;
        }
    }

    &__logo {
        width: 27rem;
        height: 5.6rem;
        vertical-align: middle;
        @media (max-width: $phone-width) {
            width: 6.7rem;
            height: 5.3rem;
        }
    }

    &__nav {
        display: inline; 
        @media (max-width: $phone-width) {
            display: none;
        }
    }

    &__list {
        list-style: none;
        text-align: center;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    &__item {
        margin: 0 2.2rem;
    }

    &__link {
        text-decoration: none;
        font-size: 2rem;
        color: $color-white;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 2.4rem;
        letter-spacing: $letter-spacing-default;
        transition: all .1s ease-out;

        &:hover, &:active {
            padding-bottom: 6px;
            border-bottom: 4px solid $color-white;
        }

        &--purple {
            color: $color-primary;

            &:hover, &:active {
                border-bottom: 4px solid $color-primary;
            }            
        }
    }
    .active {
        padding-bottom: 6px;
        border-bottom: 4px solid $color-white;

        @media (max-width: $phone-width) {
            padding-bottom: 0;
            border-bottom: none;
            .navigation__link {
                color: $color-primary-lighter;
            }
            
        }
    }
    .active-purple {
        padding-bottom: 6px;
        border-bottom: 4px solid $color-primary;
    }
    &__checkbox-mobile, &__button-mobile, 
    &__nav-mobile, &__background-mobile {
        display: none;
    }

    @media (max-width: $phone-width) {
        &__checkbox-mobile {
            display: none;
        }
    
        &__button-mobile {
            display: block;
            width: 6.8rem;
            height: 6rem;
            padding: 1.9rem 2rem;
            position: fixed;
            top: 2rem;
            right: 0rem;
            z-index: 2000;
            text-align: center;
            cursor: pointer;
        }
    
        &__nav-mobile {
            display: block;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1500;
            opacity: 0;
            width: 0;
            transition: opacity .4s;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 44.8rem;
            padding: 2rem;
        }

        &__nav-list-mobile {
            position: absolute;
            padding: 0 3rem;
            top: 15rem;
            left: -20rem;
            transition: all .4s;
            text-align: left;
            width: 0;
        }
        &__get-in-touch {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            color: $color-white;
            opacity: 0;
            margin-top: 10rem;

            .email-link-navigation {
                font-size: 2.4rem;
                line-height: 3.2rem;
                font-weight: 800;
                color: #fff;
            }
            p {
                font-size: 1.2rem;
                line-height: 2rem;
                font-weight: bold;
            }
        }
    
        &__list {
            list-style: none;
            width: 100%;
            display: block;
        }
        &__background-mobile {
            display: block;
            height: 6rem;
            width: 6.8rem;
            position: fixed;
            top: 2rem;
            right: 0rem;
            background: transparent;
            z-index: 1000;
            // transition: transform .8s, background .8s;
        }
    
        &__item {
            text-align: left;
            margin: 0;
            margin-bottom: 1.2rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    
        &__link {
            &:link,
            &:visited {
                display: inline-block;
                font-size: 3.6rem;
                line-height: 4.4rem;
                font-weight: 800;
                color: $color-white;
                text-decoration: none;
                text-transform: capitalize;
                background-size: 220%;
                transition: all .4s;
                display: none;
    
                span {
                    margin-right: 1.5rem;
                    display: inline-block;
                }
            }
            
            &:hover,
            &:active {
                color: $color-primary-light;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    
        &__checkbox-mobile:checked ~ &__background-mobile {
            background: $color-primary;
            transform: scale(80);
        }
        &__checkbox-mobile:checked ~ &__nav-mobile
        {
            opacity: 1;
            width: 100%;
            z-index: 1000;
        }
        &__checkbox-mobile:checked ~ &__nav-mobile .navigation__nav-list-mobile {
            width: 100%;
            left: 0;
        }
        &__checkbox-mobile:checked ~ &__nav-mobile .navigation__link {
            display: inline-block;
        }
        &__checkbox-mobile:checked ~ &__nav-mobile .navigation__get-in-touch {
            opacity: 1;
        }
    
    
        //ICON
        &__icon {
            position: relative;
    
            &,
            &::before,
            &::after {
                width: 2.8rem;
                height: 4px;
                background-color: $color-white;
                display: inline-block;
                vertical-align: middle;
            }

            &--purple {
                &,
                &::before,
                &::after {
                width: 2.8rem;
                height: 4px;
                background-color: $color-primary;
                display: inline-block;
                vertical-align: middle;
                }
            }
    
            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                transition: all .2s;
            }
    
            &::before { top: -.8rem; }
            &::after { top: .8rem; }
        }
    
        &__checkbox-mobile:checked + &__button-mobile &__icon {
            background-color: transparent;
        }
    
        &__checkbox-mobile:checked + &__button-mobile &__icon::before {
            top: 0;
            transform: rotate(135deg);
        }
        &__checkbox-mobile:checked + &__button-mobile &__icon--purple::before {
            background-color: $color-white;
        }
    
        &__checkbox-mobile:checked + &__button-mobile &__icon::after {
            top: 0;
            transform: rotate(-135deg);
        }
        &__checkbox-mobile:checked + &__button-mobile &__icon--purple::after {
            background-color: $color-white;
        }
    }
    
}