.section-games {
    &__game-container {
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
        margin: 0 auto;
        width: 112rem;

        @media (max-width: $tablet-width) {
            width: auto;
            padding: 0 6rem;
        } 

        @media (max-width: $phone-width) {
            width: auto;
            padding: 0 3rem;
            gap: 2.4rem;
        }

        &--grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            margin: 0 auto;
            gap: 4.8rem 2rem;

            @media (max-width: $tablet-width) {
                grid-template-columns: repeat(3, 1fr);
                margin: auto;
            }

            @media (max-width: $phone-width) {
                grid-template-columns: repeat(2, 1fr);
                gap: 1.6rem 1rem;
            }

            .game-item {
                display: flex;
                gap: 1.2rem;
                flex-direction: column;
                width: 26.5rem;
                cursor: pointer;
                position: relative;

                @media (max-width: $phone-width) {
                    width: 15.2rem;
                    gap: 8px;
                }
                

                .game-img-rating {
                    position: relative;
                    // background: url('/assets/img/fireToad.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 26.5rem;
                    transition: all .1s;
                    @media (max-width: $phone-width) {
                        height: 15.2rem;
                    }
                    &:hover {
                        transform: scale(1.02);
                    }
                    &.provider img {
                        padding: 50px;
                        @media (max-width: $phone-width) {
                            padding: 20px;
                        }
                    }
                    .game-rating {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: rgba($color-primary-blue, 0.72);
                        padding-top: 1.2rem;
                        padding-bottom: 8px;
                        width: 100%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 4px;
                        @media (max-width: $phone-width) {
                            padding-top: 8px;
                        }

                        .stars-holder {
                            display: flex;
                            justify-content: center;
                            gap: 4px;
                            align-items: center;

                            img {
                                width: 1.3rem;
                                height: 1.2rem;
                                @media (max-width: $phone-width) {
                                    width: 8px;
                                    height: 7px;
                                }
                            }
                        }
                    }
                }

                .game-name-provider {
                    display: flex;
                    flex-direction: column;

                    .headline-5 {
                        text-transform: capitalize;
                    }
                    .game-provider-name {
                        font-weight: 500;
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        color: $color-text-light;
                        @media (max-width: $phone-width) {
                            font-size: 1.2rem;
                            line-height: 2rem;
                        }
                    }
                }
            }
       } 
    }

    .row {
        align-items: flex-start;
        gap: 6.4rem;
        width: 112rem;
        margin: 0 auto;

        @media (max-width: $tablet-width) {
            width: auto;
            padding-left: 6rem;
            padding-right: 6rem;
        }
        @media (max-width: $phone-width) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }

        .section-statistics__text-holder {
            width: 64.5rem;
            margin-top: 8rem;
            @media (max-width: $tablet-width) {
                width: 49%;
            }

            @media (max-width: $phone-width) {
                width: 100%;
                margin-top: 0;
                gap: 3.2rem;

                .section-statistics__headline-holder{
                    padding: 0 3rem;
                }
                .backgrounds-and-text {
                    padding: 0 3rem;
                }
                .btn-holder {
                    padding: 0 3rem;
                }
            }
            
        }
    }
    &__latest-rectangle-holder {
        position: relative;
        width: 45.5rem;
        height: 50rem;;

        @media (max-width: $phone-width) {
            display: none;
        }

        .rectangle {
            position: absolute;
            background: $color-primary-blue;

            &--first {
                width: 9.5rem;
                height: 9.5rem;
                top: 0;
                left: 0;
                padding: 1.75rem 1.5rem;
            }
            &--second {
                left: 9.5rem;
                top: 9.5rem;
                width: 26.5rem;
                height: 26.5rem;
                display: flex;
                flex-direction: column;
                gap: 1.6rem;
                padding: 2.4rem 3.2rem 2.8rem 3.2rem;

                .rectangle-question {
                    font-weight: 700;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    color: $color-primary-light;
                    text-transform: uppercase;
                }
                .rectangle-answer {
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    font-weight: 700;
                    color: $color-white-darker;
                }
            }
            &--thired {
                width: 9.5rem;
                height: 9.5rem;
                top: 36rem;
                left: 36rem;
            }
            &--fourth {
                top: 45.5rem;
                width: 4.5rem;
                height: 4.5rem;
                left: 31.5rem;
            }
        }
    }
    &__latest-rectangle-holder-mobile {
        display: none;
        @media (max-width: $phone-width) {
            display: block;
            position: relative;
            width: 100%;
            height: 19rem;

            .rectangle-about {
                position: absolute;
                background: $color-primary-blue;
        
                &--first {
                    @media (max-width: $phone-width) {
                        width: 35.6rem;
                        height: 19rem;
                        right: 0;
                        bottom: 0rem;
                        gap: 8px;
                        padding: 2rem;
                        display: flex;
                        flex-direction: column;
                        gap: 1.6rem;
                    }
                    @media (max-width: $small-devices-width) {
                        width: 23.6rem;
                        height: 19rem;
                        right: 0;
                        bottom: 0rem;
                        gap: 8px;
                        padding: 2rem;
                    }
                    .rectangle-question {
                        @media (max-width: $phone-width) {
                            font-weight: 700;
                            color: $color-primary-light;
                            text-transform: uppercase;
                            font-size: 1.2rem;
                            line-height: 2rem;
                        }
                    }
                    .rectangle-answer {
                        @media (max-width: $phone-width) {
                            font-size: 1.8rem;
                            line-height: 2.6rem;
                            font-weight: 700;
                            color: $color-white-darker;
                        }
                        @media (max-width: $small-devices-width) {
                            font-size: 1.4rem;
                            line-height: 2rem;
                        }
                    }
                }
                &--second {
                    @media (max-width: $phone-width) {
                        bottom: 7rem;
                        width: 8rem;
                        height: 8rem;
                        right: 35.6rem;
                        padding: 1.6rem 1.3rem;

                        img {
                            width: 5.8rem;
                            height: 4.8rem;
                        }
                    }
                    @media (max-width: $small-devices-width) {
                        bottom: 7rem;
                        width: 8rem;
                        height: 8rem;
                        right: 23.6rem;
                        padding: 1.6rem 1.3rem;

                        img {
                            width: 5.8rem;
                            height: 4.8rem;
                        }
                    }
                }
                &--third {
                    @media (max-width: $phone-width) {
                        bottom: 4.1rem;
                        right: 43.6rem;
                        width: 2.9rem;
                        height: 2.9rem;
                    }
                    @media (max-width: $small-devices-width) {
                        bottom: 4.1rem;
                        right: 31.6rem;
                        width: 2.9rem;
                        height: 2.9rem;
                    }
                }
                &--purple {
                    background-color: $color-yellow-highlight;
                }
            }
        }
    }
    &__popular-rectangle-holder {
        position: relative;
        width: 45.5rem;
        height: 50rem;

        @media (max-width: $phone-width) {
            display: none;
        }

        .rectangle {
            position: absolute;
            background: $color-primary-blue;

            &--first {
                width: 9.5rem;
                height: 9.5rem;
                top: 0;
                left: 36rem;
                padding: 1.75rem 1.5rem;
            }
            &--second {
                left: 9.5rem;
                top: 9.5rem;
                width: 26.5rem;
                height: 26.5rem;
                display: flex;
                flex-direction: column;
                gap: 1.6rem;
                padding: 2.4rem 3.2rem 2.8rem 3.2rem;

                .rectangle-question {
                    font-weight: 700;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    color: $color-primary-light;
                    text-transform: uppercase;
                }
                .rectangle-answer {
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    font-weight: 700;
                    color: $color-white-darker;
                }
            }
            &--thired {
                width: 9.5rem;
                height: 9.5rem;
                top: 36rem;
                left: 0rem;
            }
            &--fourth {
                top: 45.5rem;
                width: 4.5rem;
                height: 4.5rem;
                left: 9.5rem;
            }
        }
    }
}

.section-all-games {
    @media (max-width: $tablet-width) {
        padding-left: 6rem;
        padding-right: 6rem; 
    }
    @media (max-width: $phone-width) {
        padding-left: 0;
        padding-right: 0; 
    }
    &__game-container {
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
        width: 112rem;
        margin: 0 auto;
        @media (max-width: $tablet-width) {
           width: auto;
            
        }
        @media (max-width: $phone-width) {
            width: 100%;
            padding: 0 3rem;
            gap: 3.2rem;
        }
    

        &--grid {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 2rem;
            @media (max-width: $tablet-width) {
                grid-template-columns: repeat(5, 1fr);
                
            }
            @media (max-width: 1050px) {
                grid-template-columns: repeat(4, 1fr);
             }
            
            @media (max-width: $small-devices-width) {
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;
            }
            .game-item {
                background-position: center!important;
                background-repeat: no-repeat!important;
                background-size: cover!important;
                height: 17rem;
                width: 17rem;
                overflow: hidden;
                cursor: pointer;
                position: relative;
                .thumbnail {
                    position: absolute;
                    top: 0px;
                    z-index: 2;
                    opacity: 1;
                    &:hover {
                        opacity: 0.1;
                    }
                }
                @media (max-width: $phone-width) {
                    width: 9.8rem;
                    height: 9.8rem;
                }

                .game-item-hover {
                    opacity: 1;
                    z-index: 1;
                    background: rgba($color-primary-blue, .88);
                    display: flex;
                    flex-direction: column;
                    gap: 2.2rem;
                    justify-content: flex-end;
                    height: 100%;
                    padding: 1.2rem;
                    @media (max-width: $phone-width) {
                        display: none;
                    }

                    .game-name-provider {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        align-items: center;
                        text-align: center;

                        .game-name {
                            color: $color-white;
                            font-size: 1.8rem;
                            line-height: 2.2rem;
                            font-weight: 700;
                            text-transform: capitalize;
                        }
                        .game-provider-name {
                            font-size: 1.2rem;
                            color: $color-white;
                            line-height: 1.4rem;
                            font-weight: 500;
                        }
                    }
                    .game-rating {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 6px;

                        .stars-holder {
                            height: 1.3rem;
                        }

                        img {
                            width: 1.3rem;
                            height: 1.2rem;
                        }

                        p {
                            font-size: 1rem;
                            line-height: 1.6rem;
                            color: $color-white-darker;
                            font-weight: 700;
                        }
                    }

                }
                // &:hover .game-item-hover {
                //     opacity: 0.8;
                // }
            }
        }
        .bullets-arrows-holder {
            display: flex;
            justify-content: space-between;

            .bullets-holder {
                display: flex;
                gap: 2rem;
                align-items: center;

                .bullet {
                    width: 1rem;
                    height: 1rem;
                    background: rgba($color-primary-blue, 0.24);
                    border-radius: 50%;
                    @media (max-width: $phone-width) {
                        width: 6px;
                        height: 6px;
                    }
                }
                .bullet.active {
                    width: 2rem;
                    height: 2rem;
                    background: $color-primary-blue;
                    border-radius: 50%;
                    @media (max-width: $phone-width) {
                        width: 1.2rem;
                        height: 1.2rem;
                    }
                }
            }
        }
        .arrow-holder {
            svg {
                width: 5rem;
                height: 4.2rem;
                cursor: pointer;
                @media (max-width: $phone-width) {
                    width: 3.8rem;
                    height: 3.2rem;
                }
            }
            .arrow-left {
                transform: rotateY(180deg);
                margin-right: 4rem;
                @media (max-width: $phone-width) {
                    margin-right: 1.6rem;
                }
            }
        }
    }

}